import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BCardBody,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'personal', label: 'Zone Tagging', sortable: true },
        { key: 'action', sortable: true },
        { key: 'wbs', sortable: true },
        { key: 'planning.startTime', label: 'Planning (Start time)', sortable: true },
        { key: 'planning.endTime', label: 'Planning (End time)', sortable: true },
        { key: 'actual.startTime', label: 'Actual (Start  time)', sortable: true },
        { key: 'actual.endTime', label: 'Actual (End time)', sortable: true },
        { key: 'code', sortable: true },
      ],
      /* eslint-disable global-require */
      tableData: [
      ],
      /* eslint-disable global-require */
      // status: [{
      //   1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      // },
      // {
      //   1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      // }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Overdue: 'light-danger',
        Completed: 'light-success',
        Pending: 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // data
    this.$http.get('/map/data')
      .then(response => {
        this.tableData = response.data.products
      })
  },
  // created() {
  //   this.$http.get('/analytics/data')
  //     .then(response => {
  //       const dashboardData = response.data
  //       this.items = dashboardData.listProjectTeam.projectTeam
  //     })
  // },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
