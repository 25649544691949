<template>
  <div
    class="q-pa-sm"
    style="position: relative"
  >
    <div id="map">
      <div style="position: absolute; z-index: 1; left: 20px;">
        <button
          class="mt-2 btn btn-primary"
          icon="mdi-plus"
          @click="toggleCreatePolygon"
        >+ Create New Area</button>
      </div>
    </div>
    <transition
      name="fade-bottom"
      mode="out-in"
      appear
    >
      <div
        v-show="display.table"
        class="rounded bg-white p-1"
        style="position: absolute; top: 590px; right:7px; z-index: 12; cursor: pointer;"
        @click="display.table = false"
      >
        <feather-icon
          icon="XIcon"
        /></div>
    </transition>
    <transition
      name="fade-bottom"
      mode="out-in"
      appear
    >
      <div
        v-show="display.table"
        style="position: absolute; top: 600px; width: 100%; z-index: 10"
        class="p-2"
      >
        <div
          class="bg-white p-0 m-0"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableData"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(personal)="data">
              <div
                class="d-flex align-items-center"
                @click="toggleGanttChart(data.item)"
              >
                <b-avatar />
                <span class="ml-2 font-weight-bolder"> {{ data.item.personal.name }}</span>
              </div>
            </template>
            <template #cell(code)="data">
              <b-badge :variant="data.item.class">
                {{ data.item.code }}
              </b-badge>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </div>
      </div>
    </transition>
    <transition
      name="fade-bottom"
      mode="out-in"
      appear
    >
      <div
        v-show="display.gantt"
        class="rounded bg-white pr-10"
        style="position: absolute; top: 590px; right:50px; z-index: 12"
        @click="backButton()"
      >
        <feather-icon
          icon="ArrowLeftCircleIcon"
        /><span style="color: #5E5873; font-weight: 500"> Back</span></div>
    </transition>
    <transition
      name="fade-bottom"
      mode="out-in"
      appear
    >
      <div
        v-show="display.gantt"
        style="position: absolute; top: 600px; width: 100%; z-index: 10"
        class="p-2"
      >
        <div class="card rounded">
          <span class="p-2 font-weight-bold">Gantt Chart - NPT {{ currentGantt }}
          </span>
          <gantt-elastic
            :options="options"
            :tasks="tasks"
            @tasks-changed="tasksUpdate"
            @options-changed="optionsUpdate"
            @dynamic-style-changed="styleUpdate"
          >
            <gantt-header slot="header" />
          </gantt-elastic>
        </div>
      </div>
    </transition>
    <div class="q-mt-md" />
    <!-- <button
      class="mt-2 btn btn-primary"
      icon="mdi-plus"
      @click="addTask"
    >+ Add Task</button> -->
  </div>
</template>

<style>
.mapboxgl-canvas {
  width: 100% !important;
}
  #map {
    height: 1000px;
    width: 100%;
    position: relative;
    /* background-color: blue; */
  }
.gantt-elastic__header{
  display: none !important;
}

</style>

<script>
import GanttElastic from "gantt-elastic"
import GanttHeader from "gantt-elastic-header"
import dayjs from "dayjs"
import moment from 'moment'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import zoneMixin from './zone'

// just helper to get current dates
function getDate(hours) {
  console.log('sdsd')
  // const currentDate = new Date()
  const currentDate = new Date()
  console.log(currentDate)
  const currentDateTest = moment("2021-09-28", 'YYYY-MM-DD').toDate()
  console.log(currentDateTest)
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const currentDay = currentDate.getDate()
  const timeStamp = new Date(
    currentYear,
    currentMonth,
    currentDay,
    0,
    0,
    0,
  ).getTime()
  const dateTest = new Date(timeStamp + hours * 60 * 60 * 1000).getTime()
  // const dateTest = new Date(timeStamp * 60 * 60 * 1000).getTime()
  console.log(dateTest)
  return dateTest
}
const tasks = [
  {
    id: 1,
    label: "PPE Inspection",
    user:
      '<a href="https://www.google.com/search?q=John+Doe" target="_blank" style="color:#0077c0;">John Doe</a>',
    start: moment("2021-09-27", 'YYYY-MM-DD').toDate().valueOf(),
    // duration: 15 * 24 * 60 * 60 * 1000,
    end: moment("2021-10-19", 'YYYY-MM-DD').toDate().valueOf(),
    percent: 85,
    type: "project",
    // collapsed: true,
  },
  {
    id: 2,
    label: "Engineering Team Site Visit",
    user:
      '<a href="https://www.google.com/search?q=John+Doe" target="_blank" style="color:#0077c0;">John Doe</a>',
    start: moment("2021-10-04", 'YYYY-MM-DD').toDate().valueOf(),
    // duration: 15 * 24 * 60 * 60 * 1000,
    end: moment("2021-10-25", 'YYYY-MM-DD').toDate().valueOf(),
    percent: 85,
    type: "project",
    // collapsed: true,
  },
  {
    id: 3,
    label: "Project Briefing",
    user:
      '<a href="https://www.google.com/search?q=John+Doe" target="_blank" style="color:#0077c0;">John Doe</a>',
    start: moment("2021-10-23", 'YYYY-MM-DD').toDate().valueOf(),
    // duration: 15 * 24 * 60 * 60 * 1000,
    end: moment("2021-11-01", 'YYYY-MM-DD').toDate().valueOf(),
    percent: 85,
    type: "project",
    // collapsed: true,
  },
  {
    id: 4,
    label: "Project Start",
    user:
      '<a href="https://www.google.com/search?q=John+Doe" target="_blank" style="color:#0077c0;">John Doe</a>',
    start: moment("2021-10-23", 'YYYY-MM-DD').toDate().valueOf(),
    // duration: 15 * 24 * 60 * 60 * 1000,
    end: moment("2021-11-28", 'YYYY-MM-DD').toDate().valueOf(),
    percent: 85,
    type: "project",
    // collapsed: true,
  },
  {
    id: 5,
    label: "Project Review",
    user:
      '<a href="https://www.google.com/search?q=John+Doe" target="_blank" style="color:#0077c0;">John Doe</a>',
    start: moment("2021-10-23", 'YYYY-MM-DD').toDate().valueOf(),
    // duration: 15 * 24 * 60 * 60 * 1000,
    end: moment("2021-12-03", 'YYYY-MM-DD').toDate().valueOf(),
    percent: 85,
    type: "project",
    // collapsed: true,
  },
]
const options = {
  taskMapping: {
    progress: "percent",
  },
  maxRows: 100,
  maxHeight: 500,
  title: {
    label: "Prism Timeline",
    html: false,
  },
  row: {
    height: 24,
  },
  calendar: {
    hour: {
      display: true,
    },
  },
  chart: {
    progress: {
      bar: false,
    },
    expander: {
      display: true,
    },
  },
  taskList: {
    expander: {
      straight: false,
    },
    columns: [
      {
        id: 1,
        label: "ID",
        value: "id",
        width: 40,
      },
      {
        id: 2,
        label: "Description",
        value: "label",
        width: 200,
        expander: true,
        html: true,
        events: {
          // eslint-disable-next-line no-unused-vars
          click({ data, column }) {
            // eslint-disable-next-line no-alert
            alert(`description clicked!\n${data.label}`)
          },
        },
      },
      {
        id: 3,
        label: "Assigned to",
        value: "user",
        width: 130,
        html: true,
      },
      {
        id: 3,
        label: "Start",
        value: task => dayjs(task.start).format("YYYY-MM-DD"),
        width: 78,
      },
      {
        id: 4,
        label: "Type",
        value: "type",
        width: 68,
      },
      {
        id: 5,
        label: "%",
        value: "progress",
        width: 35,
        style: {
          "task-list-header-label": {
            "text-align": "center",
            width: "100%",
          },
          "task-list-item-value-container": {
            "text-align": "center",
            width: "100%",
          },
        },
      },
    ],
  },
  locale: {
    name: "en",
    Now: "Now",
    "X-Scale": "Zoom-X",
    "Y-Scale": "Zoom-Y",
    "Task list width": "Task list",
    "Before/After": "Expand",
    "Display task list": "Task list",
  },
}

export default {
  name: "Gantt",
  components: {
    GanttElastic,
    GanttHeader,
  },
  mixins: [zoneMixin],
  data() {
    return {
      currentGantt: '',
      tasks,
      options,
      dynamicStyle: {},
      lastId: 16,
      accessToken: 'pk.eyJ1IjoicmV4emFjazkiLCJhIjoiY2sxYTF6d2QyMDRlZjNkdDlyM3Z5N24zMSJ9.U5TuDBZwdUsnwntE1h2w6w',
      map: '',
      draw: '',
      display: {
        gantt: false,
        table: false,
      },
    }
  },
  mounted() {
    mapboxgl.accessToken = this.accessToken

    this.map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      // center: [-68.137343, 45.137451], // starting position
      center: [101.65204, 2.90128],
      zoom: 17, // starting zoom
    })

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        // polygon: this.isPolygon,
        trash: true,
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      // defaultMode: 'draw_polygon',
    })
    this.map.addControl(this.draw)

    const nav = new mapboxgl.NavigationControl()
    this.map.addControl(nav, 'top-right')

    this.map.on('load', () => {
      this.map.resize()
      this.map.addSource('polygon-1', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                site: 'Site A',
              },
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [101.652066, 2.901536],
                    [101.652650, 2.901197],
                    [101.652296, 2.900632],
                    [101.651726, 2.901087],
                    [101.652066, 2.901536],
                  ],
                ],
              },
            },
            {
              type: 'Feature',
              properties: {
                site: 'Site B',
              },
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [101.651744, 2.901731],
                    [101.651533, 2.902248],
                    [101.650932, 2.901895],
                    [101.651055, 2.901402],
                    [101.651744, 2.901731],
                  ],
                ],
              },
            },
            {
              type: 'Feature',
              properties: {
                site: 'Site C',
              },
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [101.652906, 2.902463],
                    [101.652466, 2.902109],
                    [101.653174, 2.901997],
                    [101.652906, 2.902463],
                  ],
                ],
              },
            },
          ],
        },
      })

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: 'polygon-1',
        type: 'fill',
        source: 'polygon-1', // reference the data source
        layout: {},
        paint: {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.2,
        },
      })
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: 'outline-1',
        type: 'line',
        source: 'polygon-1',
        layout: {},
        paint: {
          'line-color': '#0080ff',
          'line-width': 2,
        },
      })
    })

    this.map.on('click', 'polygon-1', data => {
      console.log(data.features[0].properties.site)
      // this.currentGantt = data.features[0].properties.site
      this.display.table = !this.display.table
      this.display.gantt = false
    })
  },
  methods: {
    backButton(){
      this.display.gantt = false
      this.display.table = true
    },
    toggleGanttChart(data){
      this.currentGantt = data.personal.name
      this.display.table = !this.display.table
      this.display.gantt = !this.display.gantt
    },
    addTask() {
      this.tasks.push({
        // eslint-disable-next-line no-plusplus
        id: this.lastId++,
        label:
          '<a href="https://images.pexels.com/photos/423364/pexels-photo-423364.jpeg?auto=compress&cs=tinysrgb&h=650&w=940" target="_blank" style="color:#0077c0;">New Task Heree</a>',
        user:
          '<a href="https://images.pexels.com/photos/423364/pexels-photo-423364.jpeg?auto=compress&cs=tinysrgb&h=650&w=940" target="_blank" style="color:#0077c0;">Who?</a>',
        start: getDate(24 * 3),
        duration: 1 * 24 * 60 * 60 * 1000,
        percent: 50,
        type: "project",
      })
    },
    tasksUpdate(data) {
      this.tasks = data
    },
    optionsUpdate(data) {
      this.options = data
    },
    styleUpdate(style) {
      this.dynamicStyle = style
    },
    toggleCreatePolygon() {
      this.draw.changeMode('draw_polygon')
      this.addTask()
    },
  },
}
</script>
